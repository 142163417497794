import React from "react";
import Header from "../Header";
import { LayoutWrapper } from "./styles";
import Footer from "../Footer";
import { Dots } from "react-preloaders";

const Layout = ({
  title,
  description,
  fullHeightHeader,
  showReservationChecker,
  showFooterReservationChecker,
  children,
  headerBackground,
  loading = false,
}) => {
  return (
    <LayoutWrapper>
      {!loading && (
        <>
          <Header
            title={title}
            fullHeight={fullHeightHeader}
            description={description}
            showReservationChecker={showReservationChecker}
            background={headerBackground}
          />
          {children}
        </>
      )}
      <Dots customLoading={loading} />
      <Footer showReservationChecker={showFooterReservationChecker} />
    </LayoutWrapper>
  );
};

export default Layout;
