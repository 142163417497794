import styled from "styled-components";
import ArrowDown from "../../assets/arrow_down.png";
import ArrowDownInvert from "../../assets/arrow_down_invert.png";

const variants = {
  default: {
    height: "150px",
    columns: "auto repeat(4, 1fr)",
    rows: "auto",
    span: 1,
    buttonWidth: "100%",
    buttonMargin: 0,
  },
  compact: {
    height: "auto",
    columns: "repeat(2, 1fr)",
    rows: "auto",
    span: 2,
    buttonWidth: "100%",
    buttonMargin: 0,
  },
  footer: {
    height: "auto",
    columns: "repeat(3, auto)",
    rows: "auto",
    span: 3,
    buttonWidth: "auto",
    buttonMargin: "20px",
  },
};

const colors = (props) => ({
  default: {
    textColor: "#7D7D7D",
    buttonBackground: props.theme.accentColor,
    buttonColor: "white",
    background: "white",
    strongColor: "black",
    arrow: ArrowDown,
  },
  alternative: {
    textColor: "white",
    buttonBackground: "white",
    buttonColor: props.theme.accentColor,
    background: props.theme.accentColor,
    strongColor: "white",
    arrow: ArrowDownInvert,
  },
});

export const ReservationCheckerWrapper = styled.div.attrs((props) => ({
  variant: props.variant || "default",
  color: props.color || "default",
}))`
  display: grid;
  grid-template-columns: ${(props) => variants[props.variant].columns};
  height: ${(props) => variants[props.variant].height};
  grid-template-rows: ${(props) => variants[props.variant].rows};
  background: ${(props) => colors(props)[props.color].background};
  justify-self: center;
  color: ${(props) => colors(props)[props.color].textColor};
  justify-items: center;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;

  .popup-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
  }

  .popup-content {
    width: auto !important;
    padding: 0 !important;
    z-index: 10 !important;
  }

  .rdrDateDisplayItem,
  select,
  .rdrNextPrevButton,
  .rdrInRange,
  .rdrDayInPreview,
  .rdrStartEdge,
  .rdrEndEdge,
  .rdrDayStartPreview,
  .rdrDayEndPreview,
  .rdrDay {
    border-radius: 0 !important;
  }

  & > * {
    padding: 20px 35px;
  }

  .info {
    text-align: left;
    font-size: 1.8rem;
    font-family: "Playfair Display";
    grid-column-end: span ${(props) => variants[props.variant].span};

    br {
      display: ${(props) => (props.variant === "footer" ? "none" : "initial")};
    }

    strong {
      color: ${(props) => colors(props)[props.color].strongColor};
    }
  }

  .button {
    background: ${(props) => colors(props)[props.color].buttonBackground};
    font-size: 1.4rem;
    font-weight: 700;
    color: ${(props) => colors(props)[props.color].buttonColor};
    width: ${(props) => variants[props.variant].buttonWidth};
    align-self: stretch;
    grid-column-end: span ${(props) => variants[props.variant].span};
    margin-top: ${(props) => variants[props.variant].buttonMargin};
    font-family: Poppins;
    flex-direction: column;
    padding-top: ${(props) => (props.variant === "footer" ? "14px" : "30px")};
    padding-bottom: ${(props) =>
      props.variant === "footer" ? "14px" : "30px"};
    /* text-align: left; */
    /* color: white; */
    /* box-sizing: content-box; */

    &:hover {
      border: ${(props) =>
        props.color === "alternative"
          ? "1px solid white"
          : "1px solid transparent"};
    }

    .arrow {
      margin-left: 15px;
    }

    &.unavailable {
      background: palevioletred;
      border-color: palevioletred;
      color: white;
    }

    &.available,
    &.done {
      background: limegreen;
      border-color: limegreen;
      color: white;
    }
  }

  .guestpicker {
    display: grid;
    grid-template-areas: "text text" "number arrowUp" "number arrowDown";
    align-items: center;
    line-height: 1;
    grid-gap: 25px 35px;

    .text {
      grid-area: text;
    }

    .number {
      grid-area: number;
      font-family: "Playfair Display";
      font-size: 4.4rem;
      color: ${(props) => colors(props)[props.color].strongColor};
    }

    .arrowUp,
    .arrowDown {
      width: 12px;
      height: 7px;
      cursor: pointer;
      justify-self: center;
    }

    .arrowUp {
      grid-area: arrowUp;
      background: url(${(props) => colors(props)[props.color].arrow});
      transform: rotate(180deg);
    }

    .arrowDown {
      grid-area: arrowDown;
      background: url(${(props) => colors(props)[props.color].arrow});
    }
  }

  .datepicker {
    display: grid;
    grid-template-areas: "text text" "day month" "day arrow";
    align-items: center;
    line-height: 1;
    grid-gap: 25px 35px;
    cursor: pointer;

    .text {
      grid-area: text;
    }

    .day {
      grid-area: day;
      font-family: "Playfair Display";
      font-size: 4.4rem;
      color: ${(props) => colors(props)[props.color].strongColor};
    }

    .arrow {
      width: 12px;
      height: 7px;
      background: url(${(props) => colors(props)[props.color].arrow});
      cursor: pointer;
      justify-self: center;
    }
  }

  @media screen and (max-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: row;
    height: auto;
    width: 100%;
    gap: 10px;

    .button,
    .info {
      grid-column-end: span 3;
    }

    & > * {
      padding: 10px;
    }

    .datepicker {
      gap: 5px 10px;
    }

    .guestpicker {
      gap: 5px 10px;
    }
  }
`;
