import styled from 'styled-components'

export const NavigationWrapper = styled.nav`
  display: grid;
  /* justify-content: space-between; */
  grid-auto-flow: column;
  grid-gap: 50px;
  grid-template-columns: auto 1fr auto;
  /* justify-content: start; */
  
  
  a {
    text-decoration: none;
    color: currentColor;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 0.85;
    }
  }

  .brand {
    display: flex;
    font-size: 1.6rem;
    align-items: center;
    justify-content: center;
    width: auto;
    padding: 5px 20px;
    border: 2px solid white;
    font-family: 'Playfair Display';
    font-weight: 700;
  }

  .reserve-now {
    padding: 16px 40px;
    background: ${props => props.theme.accentColor};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    font-family: Lato;

    svg {
      margin-left: 24px;
    }
  }

  .hamburger-button {
    display: none;
  }

  ul {
    display: grid;
    font-size: 1.4rem;
    list-style-type: none;
    grid-auto-flow: column;
    grid-gap: 65px;
    justify-content: start;
    padding: 0;
    font-weight: 500;
  }

  @media screen and (max-width: 1280px) {
    grid-template-columns: auto auto;
    grid-auto-flow: row;
    grid-gap: 15px;
    align-items: center;
    justify-content: space-between;

    .brand {
      justify-self: start;
    }

    .hamburger-button {
      display: block;
      cursor: pointer;
      justify-self: end;
    }

    .reserve-now-wrapper {
      grid-column-end: span 2;
      display: none;

      &.active {
        display: block;
      }
    }

    ul {
      grid-auto-flow: row;
      justify-content: center;
      grid-column-end: span 2;
      grid-gap: 10px;
      display: none;

      &.active {
        display: grid;
      }
    }
  }
`