import axios from "axios";

const apiUrl =
  "https://uhjlbtvjlk.execute-api.eu-central-1.amazonaws.com/events";
const calendarId = "piratrezerwacje@gmail.com";

export const checkTerm = (from, to) =>
  axios
    .get(apiUrl, {
      params: {
        timeMin: from,
        timeMax: to,
        calendar: calendarId,
      },
    })
    .then((response) => response.data);

export const reserve = (from, to, guests, email, phone, name) =>
  axios
    .post(apiUrl, {
      start: from,
      end: to,
      calendar: calendarId,
      summary: `${name} - ${phone}`,
      description: `${guests} gości<br />EMail: ${email}<br />Telefon: ${phone}`,
    })
    .then((response) => response.data);

export const sendEmails = (config) =>
  axios
    .post(
      "https://pfex9q0bzf.execute-api.eu-central-1.amazonaws.com/mailing",
      config
    )
    .then((response) => response.data);
