import React from "react";
import Layout from "../../components/Layout";

import Home1 from "../../assets/home/home1.png";
import Home2 from "../../assets/home/home2.png";
import Home3 from "../../assets/home/home3.png";
import Home4 from "../../assets/home/home4.png";
import Home5 from "../../assets/home/home5.png";
import Home6 from "../../assets/home/home6.png";
import Climate from "../../assets/home/climate.png";
import Localization from "../../assets/home/localization.png";
import Smile from "../../assets/home/smile.png";
import { HomeWrapper } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";

import Rotate from "react-reveal/Rotate";
import Fade from "react-reveal/Fade";
import Slider from "../../components/Slider";
// import Prismic from "prismic-javascript";
import { PrismicClient } from "../../prismic";
import { RichText } from "prismic-reactjs";

const Home = () => {
  const [doc, setDocData] = React.useState(null);

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await PrismicClient.getSingle("homepage");
      if (response) {
        setDocData(response.data);
      }
    };
    fetchData();
  }, []);

  return (
    <Layout
      title={doc?.title}
      description={doc?.description}
      showReservationChecker
      fullHeightHeader
      loading={!doc}
    >
      <HomeWrapper>
        <div className="content-wrapper">
          <div className="title">
            <div className="shadow">U pirata</div>
            <h3>KILKA SŁÓW O NASZYM APARTAMENCIE</h3>
          </div>
          <div className="grid-1">
            <Rotate bottom right>
              <div className="text">
                <RichText render={doc?.section_1_title} />
                <hr />
                <RichText render={doc?.section_1_content} />
                <Link to="/apartament" className="button">
                  O APARTAMENCIE
                  <FontAwesomeIcon
                    className="more"
                    icon={faLongArrowAltRight}
                  />
                </Link>
              </div>
            </Rotate>
            <Fade right cascade>
              <div className="photo">
                <img src={Home1} alt="apartament" />
              </div>
            </Fade>
            <Fade delay={250} right cascade>
              <div className="photo">
                <img src={Home2} alt="apartament" />
              </div>
            </Fade>
          </div>
          <Fade right>
            <hr className="hr" />
          </Fade>
          <div className="grid-2">
            <Rotate bottom left>
              <div className="text">
                <RichText render={doc?.section_2_title} />

                <hr />
                <RichText render={doc?.section_2_content} />
                <Link className="button" to="/galeria">
                  CAŁA GALERIA
                  <FontAwesomeIcon
                    className="more"
                    icon={faLongArrowAltRight}
                  />
                </Link>
              </div>
            </Rotate>
            <Fade right>
              <div className="photo">
                <img src={Home3} alt="apartament" />
              </div>
            </Fade>
          </div>
          <Fade right>
            <div className="grid-3">
              <div className="photo">
                <img src={Home4} alt="apartament" />
              </div>
              <div className="photo">
                <img src={Home5} alt="apartament" />
              </div>
              <div className="photo">
                <img src={Home6} alt="apartament" />
              </div>
            </div>
          </Fade>
          <div className="offer">
            <div className="info">
              <h5>NASZA OFERTA</h5>
              <h4>Dlaczego Pirat?</h4>
              <p>To urokliwa wieś w Tatrach o charakterze wypoczynkowym,</p>
            </div>
            <Fade right cascade>
              <div className="item">
                <img src={Climate} alt="klimat" />
                <RichText render={doc?.feature_1_title} />
                <hr />
                <RichText render={doc?.feature_1_content} />
                <Link to="/apartament" className="button">
                  WIĘCEJ
                  <FontAwesomeIcon
                    className="more"
                    icon={faLongArrowAltRight}
                  />
                </Link>
              </div>
              <div className="item">
                <img src={Localization} alt="lokalizacja" />
                <RichText render={doc?.feature_2_title} />
                <hr />
                <RichText render={doc?.feature_2_content} />
                <Link to="/apartament" className="button">
                  WIĘCEJ
                  <FontAwesomeIcon
                    className="more"
                    icon={faLongArrowAltRight}
                  />
                </Link>
              </div>
              <div className="item">
                <img src={Smile} alt="jakość obsługi" />
                <RichText render={doc?.feature_3_title} />
                <hr />
                <RichText render={doc?.feature_3_content} />
                <Link to="/apartament" className="button">
                  WIĘCEJ
                  <FontAwesomeIcon
                    className="more"
                    icon={faLongArrowAltRight}
                  />
                </Link>
              </div>
            </Fade>
          </div>
        </div>
        <Slider home />
      </HomeWrapper>
    </Layout>
  );
};

export default Home;
