import styled from 'styled-components'

export const PricingWrapper = styled.main`
  .main-grid {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 180px;
    align-items: start;

    p {
      color: #8A8A8A;
      font-size: 1.8rem;
    }

    .price {
      border: 1px solid #D8D8D8;
      font-size: 2.1rem;
      display: grid;
      justify-content: center;
      text-align: center;
      align-items: start;

      .text {
        font-family: 'Playfair Display';
        padding: 30px;
        color: #8A8A8A;
        font-size: 2.1rem;
      }

      strong {
        font-size: 3.8rem;
        color: #111111;
      }

      .button {
        background-color: ${props => props.theme.accentColor};
        color: white;
      }
    }
  }

  @media screen and (max-width: 1280px) {
    .main-grid {
      grid-template-columns: 1fr;
      grid-auto-flow: row;

      p {
        padding: 10px;
      }
    }
  }
`