import styled from 'styled-components'

export const RulesWrapper = styled.main`
  font-size: 1.5rem;
  color: #8A8A8A;
  padding-top: 50px;
  
  @media screen and (max-width: 1280px) {
    padding: 10px;
    
  }
`