import React from "react";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme";
import { GlobalStyle } from "./globalStyle";
import { Helmet } from "react-helmet";
import moment from "moment";
import "moment/locale/pl";
import pl from "date-fns/locale/pl";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./views/Home";
import Gallery from "./views/Gallery";
import Rules from "./views/Rules";
import Contact from "./views/Contact";
import Apartment from "./views/Apartment";
import Pricing from "./views/Pricing";
import ScrollToTop from "./components/ScrollToTop";

registerLocale("pl", pl);
setDefaultLocale("pl");
moment.locale("pl");

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Apartament w Kościelisku U PIRATA | Tani nocleg</title>
        <meta
          name="description"
          content="Apartament w Kościelisku z widokiem na Tatry, tarasem. Świetna lokalizacje, apartament mieści się na obrzeżach Tatrzańskiego Parku Narodowego."
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Poppins:wght@400;500;700&family=Lato:wght@700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <GlobalStyle />
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/galeria">
            <Gallery />
          </Route>
          <Route path="/regulamin">
            <Rules />
          </Route>
          <Route path="/kontakt">
            <Contact />
          </Route>
          <Route path="/apartament">
            <Apartment />
          </Route>
          <Route path="/cennik">
            <Pricing />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
