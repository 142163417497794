import React from "react";
import { HeaderWrapper, MobileReserveWrapper } from "./styles";
import Navigation from "../Navigation";
import ReservationChecker from "../ReservationChecker";
import Fade from "react-reveal/Fade";

import HeaderImage from "../../assets/header.jpg";

const Header = ({
  title,
  description,
  showReservationChecker,
  fullHeight,
  background = HeaderImage,
}) => {
  return (
    <>
      <HeaderWrapper
        fullHeight={fullHeight}
        background={background}
        showReservationChecker={showReservationChecker}
      >
        <div className="overlay" />
        <Navigation />
        <Fade bottom>
          <div className="content-wrapper title-wrapper ">
            {description && <h2>{description}</h2>}
            <h1>{title}</h1>
          </div>
        </Fade>
        {showReservationChecker && (
          <div className="content-wrapper reserve-desktop">
            <ReservationChecker />
          </div>
        )}
        <div className="line" />
      </HeaderWrapper>
      {showReservationChecker && (
        <MobileReserveWrapper>
          <ReservationChecker variant="compact" />
        </MobileReserveWrapper>
      )}
    </>
  );
};

export default Header;
