import React from "react";
import Layout from "../../components/Layout";
import "react-image-lightbox/style.css";
import Lightbox from "react-image-lightbox";

import { GalleryWrapper } from "./styles";
import { useState } from "react";

import HeaderImage from "../../assets/gallery.jpg";
import { PrismicClient } from "../../prismic";
import { RichText } from "prismic-reactjs";

const Gallery = () => {
  const [isOpen, setOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  const [doc, setDocData] = React.useState(null);

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await PrismicClient.getSingle("gallery");
      if (response) {
        setDocData(response.data);
      }
    };
    fetchData();
  }, []);

  return (
    <Layout title={doc?.title} headerBackground={HeaderImage} loading={!doc}>
      <GalleryWrapper>
        <div className="content-wrapper">
          <RichText render={doc?.content} />
          <div className="gallery">
            {doc?.photos.map((image, key) => (
              <div className="image-wrapper" key={key}>
                <img
                  src={image.photo.thumbnail.url}
                  className="image"
                  alt="apartament"
                  onClick={() => {
                    setImageIndex(key);
                    setOpen(true);
                  }}
                />
              </div>
            ))}
          </div>
          <iframe
            title="yt"
            height="600"
            src="https://www.youtube.com/embed/nlqszTa-DTE"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </GalleryWrapper>
      {isOpen && (
        <Lightbox
          mainSrc={doc?.photos[imageIndex].photo.url}
          nextSrc={doc?.photos[(imageIndex + 1) % doc?.photos.length].photo.url}
          prevSrc={
            doc?.photos[
              (imageIndex + doc?.photos.length - 1) % doc?.photos.length
            ].photo.url
          }
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() =>
            setImageIndex(
              (imageIndex + doc?.photos.length - 1) % doc?.photos.length
            )
          }
          onMoveNextRequest={() =>
            setImageIndex(
              (imageIndex + doc?.photos.length + 1) % doc?.photos.length
            )
          }
        />
      )}
    </Layout>
  );
};

export default Gallery;
