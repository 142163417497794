import React from "react";
import Layout from "../../components/Layout";
import { ApartmentWrapper } from "./styles";

import Bed from "../../assets/apartment/bed.png";
import Bath from "../../assets/apartment/bath.png";
import TV from "../../assets/apartment/tv.png";
import Check from "../../assets/check.png";
import ReservationChecker from "../../components/ReservationChecker";

import Fade from "react-reveal/Fade";
import Slider from "../../components/Slider";

import HeaderImage from "../../assets/apartment.jpg";
import { PrismicClient } from "../../prismic";
import { RichText } from "prismic-reactjs";
import { Helmet } from "react-helmet";

const Apartment = () => {
  const [doc, setDocData] = React.useState(null);

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await PrismicClient.getSingle("apartament");
      if (response) {
        setDocData(response.data);
      }
    };
    fetchData();
  }, []);

  return (
    <Layout
      title={doc?.title}
      description={doc?.description}
      showFooterReservationChecker
      fullHeightHeader
      headerBackground={HeaderImage}
      loading={!doc}
    >
      <Helmet>
        <title>Apartament w Kościelisku | Tatrzański Park Narodowy</title>
        <meta
          name="description"
          content="Apartament z widokiem na Tatry, tarasem w bliskim sąsiedztwie Doliny Kościeliskiej i Doliny Chochołowskiej."
        />
      </Helmet>
      <ApartmentWrapper>
        <div className="content-wrapper">
          <div className="main-grid">
            <div className="info">
              <div className="amenities">
                <Fade right>
                  <div>
                    <img src={Bed} alt="łóżko" />
                    {doc?.bed_content}
                  </div>
                  <div>
                    <img src={Bath} alt="wanna" />
                    {doc?.bath_content}
                  </div>
                  <div>
                    <img src={TV} alt="tv" />
                    {doc?.tv_content}
                  </div>
                </Fade>
              </div>
              <RichText render={doc?.content} />
            </div>
            <div className="reserve">
              <ReservationChecker variant="compact" />
            </div>
          </div>
          <hr />
          <Fade right>
            <div className="lists-grid">
              <div>
                <h4>Serwis:</h4>
                <div className="list">
                  {doc?.services.map((service, index) => (
                    <div key={index}>
                      <img src={Check} alt="" />
                      {service.name}
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <h4>Atrakcje:</h4>
                <div className="list">
                  {doc?.attractions.map((attraction, index) => (
                    <div key={index}>
                      <img src={Check} alt="" />
                      {attraction.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Fade>
        </div>
        <Slider />
        <div className="content-wrapper">
          <div className="lists-grid">
            <div>
              <RichText render={doc?.content_2} />
            </div>
            <div>
              <RichText render={doc?.content_3} />
            </div>
          </div>
        </div>
      </ApartmentWrapper>
    </Layout>
  );
};

export default Apartment;
