import styled from "styled-components";

export const HeaderWrapper = styled.header`
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-gap: 50px;
  /* justify-content: center; */
  align-items: center;
  padding: 35px 45px;
  background: url('${(props) => props.background}');
  background-size: cover;
  color: white;
  text-align: center;
  /* height: 600px; */
  /* max-height: 100vh; */
  min-height: ${(props) => (props.fullHeight ? "100vh" : 0)};
  position: relative;

  .line {
    position: absolute;

    width: 1px;
    height: 55px;

    bottom: 0;
    margin: 0 auto;
    background: #C9C9C9;
    z-index: 5;
    left: 0;
    right: 0;

    &::after {
      content: '';
      z-index: 5;
      position: absolute;
      width: 1px;
      height: 55px;
      top: 55px;
      bottom: 0;
      margin: 0 auto;
      background: ${(props) => props.theme.accentColor};
    }
  }

  h1, h2 {
    margin: 0;
    font-family: 'Playfair Display';
    font-weight: 400;
  }

  h1 {
    font-size: 6.4rem;
  }

  h2 {
    font-size: 1.8rem;
  }

  .title-wrapper {
    margin: 120px auto !important;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111111;
    opacity: 0.69;
    /* z-index: -1; */
  }

  .reserve {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  &>*:not(.overlay) {
    z-index: 2;
  }

  .reserve-mobile {
    display: none;
  }

  @media screen and (max-width: 1280px) {
    width: 100%;
    padding: 10px;
    max-height: initial;
    grid-gap: 25px;
    min-height: ${(props) =>
      props.fullHeight ? (props.showReservationChecker ? "60vh" : "100vh") : 0};

    h1 {
      font-size: 2.4rem;
    }

    h2 {
      font-size: 1.4rem;
    }

    .title-wrapper {
      margin: 15px auto !important;
    }

    .reserve-desktop {
      display: none;
    }

    .reserve-mobile {
      display: block;
    }

    .line {
      display: none;
    }
  }
`;

export const MobileReserveWrapper = styled.div`
  display: none;

  @media screen and (max-width: 1280px) {
    display: block;
  }
`;
