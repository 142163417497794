import styled from 'styled-components'

export const FooterWrapper = styled.footer`
  color: #8A8A8A;
  margin: 50px 0;

  a {
    text-decoration: none;
  }

  .reservation-wrapper {
    background: ${props => props.theme.accentColor};
    padding: 80px;
    margin-bottom: 110px;
  }

  .brand {
    display: inline-flex;
    font-size: 1.6rem;
    align-items: center;
    justify-content: center;
    padding: 5px 20px;
    border: 2px solid black;
    color: black;
    font-family: 'Playfair Display';
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    font-size: 1.5rem;
    margin: 25px 0;
    border: 1px solid #D8D8D8;

    .social {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 15px;
    }

    &>* {
      &:not(:last-child) {
        border-right: 1px solid #D8D8D8;
      }
      padding: 25px;
      display: grid;
      grid-gap: 15px;
      justify-content: center;
      align-items: center;
      text-align: center;

      strong {
        color: black;
        align-self: start;
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .copyright {
      line-height: 1;
      font-size: 1.5rem;
    }

    ul {
      display: grid;
      font-size: 1.4rem;
      list-style-type: none;
      grid-auto-flow: column;
      grid-gap: 25px;
      justify-content: start;

      a {
        text-decoration: none;
        color: currentColor;

        transition: opacity 0.2s ease;

        &:hover {
          opacity: 0.85;
        }
      }
    }
  }

  @media screen and (max-width: 1280px) {
    .grid {
      grid-template-columns: 1fr;
      grid-auto-flow: row;
    }

    .content-wrapper {
      text-align: center;
    }

    .bottom {
      flex-direction: column-reverse;

      ul {
        padding: 0;
        grid-auto-flow: row;
        text-align: center;
      }
    }

    .reservation-wrapper {
      padding: 10px;
    }
  }
`