import styled from 'styled-components'

export const ApartmentWrapper = styled.main`
  padding-top: 50px;

  .main-grid {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 50px;

    .reserve {
      border: 1px solid #D8D8D8;
    }

    .info {
      .amenities {
        display: grid;
        grid-template-columns: repeat(3, auto);
        grid-gap: 25px;
        color: #8A8A8A;
        font-size: 1.5rem;
        justify-content: start;
        margin-bottom: 25px;

        &>* {
          display: inline-flex;
          align-items: center;
        }

        img {
          margin-right: 15px;
        }
      }
      
      p {
        font-size: 1.8rem;
        color: #8A8A8A;
      }
    }
  }

  hr {
    border-color: #DBDBDB;
    margin: 50px 0;
  }

  .lists-grid {
    display: grid;
    grid-gap: 140px;
    grid-template-columns: repeat(2, 1fr);

    h4 {
      font-family: 'Playfair Display';
      font-size: 4.2rem;
      margin: 0;
      margin-bottom: 30px;
    }
    p {
      font-size: 1.5rem;
        color: #8A8A8A;
    }
    .list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;

      &>* {
        display: flex;
        align-items: center;
        font-size: 1.5rem;
        color: #8A8A8A;

        img {
          margin-right: 15px;
        }
      }
    }
  }

  @media screen and (max-width: 1280px) {
    .main-grid {
      grid-template-columns: 1fr;
      grid-auto-flow: row;
      
      .info {
        padding: 10px;

        .amenities {
          grid-template-columns: 1fr;
          grid-auto-flow: row;
          
          &>* {
            justify-content: center;
          }
        }
      }
    }

    .lists-grid {
      grid-template-columns: 1fr;
      grid-auto-flow: row;
      grid-gap: 25px;
      padding: 15px;

      .list {
        grid-template-columns: 1fr;
      }
    }
  }
`