import React from "react";
import { useState } from "react";
import styled from "styled-components";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "react-image-lightbox/style.css";

import Apartment1 from "../assets/apartmentslider/1.jpg";
import Apartment2 from "../assets/apartmentslider/2.jpg";
import Apartment3 from "../assets/apartmentslider/3.jpg";
import Apartment4 from "../assets/apartmentslider/4.jpg";
import Apartment5 from "../assets/apartmentslider/5.jpg";
import Home1 from "../assets/homeslider/1.jpg";
import Home2 from "../assets/homeslider/2.jpg";
import Home3 from "../assets/homeslider/3.jpg";

import Lightbox from "react-image-lightbox";

const apartmentImages = [
  Apartment1,
  Apartment2,
  Apartment3,
  Apartment4,
  Apartment5,
];

const homeImages = [Apartment1, Apartment4, Home1, Home2, Home3];

const responsiveHome = {
  desktop: {
    breakpoint: { max: 7000, min: 1920 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1920, min: 720 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 720, min: 0 },
    items: 1,
  },
};

const responsiveApartment = {
  desktop: {
    breakpoint: { max: 7000, min: 1920 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1919, min: 720 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 720, min: 0 },
    items: 1,
  },
};

const Slider = ({ home }) => {
  const [isOpen, setOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  const images = home ? homeImages : apartmentImages;

  return (
    <>
      <CarouselWrapper home={home}>
        <Carousel
          className="slider"
          responsive={home ? responsiveHome : responsiveApartment}
          infinite
        >
          {images.map((image, index) => (
            <div
              className="image"
              onClick={() => {
                setImageIndex(index);
                setOpen(true);
              }}
              key={index}
            >
              <img src={image} alt="zdjęcie" />
            </div>
          ))}
        </Carousel>
      </CarouselWrapper>
      {isOpen && (
        <Lightbox
          mainSrc={images[imageIndex]}
          nextSrc={images[(imageIndex + 1) % images.length]}
          prevSrc={images[(imageIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() =>
            setImageIndex((imageIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setImageIndex((imageIndex + images.length + 1) % images.length)
          }
        />
      )}
    </>
  );
};

export default Slider;

const CarouselWrapper = styled.div`
  margin: 70px 0;

  .image {
    width: 100%;
    height: ${(props) => (props.home ? 384 : 520)}px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      overflow: hidden;
      object-fit: cover;
      transition: transform 0.2s ease;
      transform-origin: center;
      cursor: pointer;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
`;
