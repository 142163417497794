import styled from 'styled-components'

export const ContactWrapper = styled.main`
  padding-top: 50px;
  display: grid;
  grid-gap: 50px;
  text-align: center;

  h5 {
    color: ${props => props.theme.accentColor};
    font-size: 1.4rem;
    margin: 0;
    letter-spacing: 0.3rem;
    font-weight: 400;
  }

  h4 {
    font-size: 3rem;
    font-family: 'Playfair Display';
    margin: 10px 0;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 25px;
    text-align: left;

    &>* {
      padding: 25px;
      display: grid;

      grid-template-columns: repeat(2, auto);
      grid-template-rows: auto 1fr;

      grid-gap: 10px 25px;

      justify-content: center;

      img {
        grid-row-end: span 2;
      }

      p {
        margin: 0;
        font-size: 1.5rem;
        color: #8A8A8A;
      }

      strong {
        font-family: 'Playfair Display';
        font-size: 2.1rem;
      }
    }
  }

  hr {
    border-color: #DBDBDB;
    margin: 50px 0;
  }

  .more {
    margin-left: 20px !important;
  }

  .info {
    color: #7D7D7D;
    font-family: 'Playfair Display';
    font-size: 1.8rem;
    width: 50%;
    margin: 0 auto;
    font-style: italic;
  }

  form {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-columns: repeat(2, auto);
    grid-gap: 15px;
    margin: 30px 0;

    input, textarea {
      border: none;
      border-bottom: 1px solid #7D7D7D;
      font-size: 1.5rem;
      font-family: Poppins;
      color: #8A8A8A;
      padding: 14px 0;
      transition: 0.2s ease border;
    }

    input:focus, textarea:focus {
      border-color: ${props => props.theme.accentColor};
    }

    textarea {
      grid-column-end: span 3;
    }

    .button {
      background: ${props => props.theme.accentColor};
      color: white;
      grid-column-start: 2;
      justify-self: center;
    }
  }

  .map {
    width: 100%;
    height: 600px;
  }

  @media screen and (max-width: 1280px) {
    .grid, form {
      grid-template-columns: 1fr;
      grid-auto-flow: row;
      padding: 10px;

      &>* {
        grid-column-start: initial !important;
        grid-column-end: initial !important;
      }
    }
  }
`