import React from "react";
import { FooterWrapper } from "./styles";
import { Link } from "react-router-dom";
import Twitter from "../../assets/twitter.png";
import Facebook from "../../assets/facebook.png";
import Instagram from "../../assets/instagram.png";
import ReservationChecker from "../ReservationChecker";

import { RichText } from "prismic-reactjs";
import { PrismicClient } from "../../prismic";

const Footer = ({ showReservationChecker = false }) => {
  const [doc, setDocData] = React.useState(null);

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await PrismicClient.getSingle("contact");
      if (response) {
        setDocData(response.data);
      }
    };
    fetchData();
  }, []);

  return (
    <FooterWrapper>
      {showReservationChecker && (
        <div className="reservation-wrapper" id="reserve">
          <div className="content-wrapper">
            <ReservationChecker variant="footer" color="alternative" />
          </div>
        </div>
      )}
      <div className="content-wrapper">
        <div className="brand">U PIRATA</div>
        <div className="grid">
          <div>
            <strong>Adres</strong>
            <div>
              <RichText render={doc?.address} />
            </div>
          </div>
          <div>
            <strong>Rezerwacja</strong>
            <a href="#reserve" className="button">
              REZERWUJ
            </a>
          </div>
          <div>
            <strong>Kontakt</strong>
            <div>
              {doc && RichText.asText(doc?.phone)}
              <br />
              {doc && RichText.asText(doc?.email)}
            </div>
          </div>
          <div>
            <strong>Dołącz do nas</strong>
            <div className="social">
              <img src={Twitter} alt="Twitter" />
              <img src={Facebook} alt="Facebook" />
              <img src={Instagram} alt="Instagram" />
            </div>
          </div>
        </div>
        <div className="bottom">
          <div className="copyright">Copyright &copy; 2020 by u Pirata</div>
          <nav>
            <ul>
              <li>
                <Link to="/apartament">Apartament</Link>
              </li>
              <li>
                <Link to="/galeria">Galeria</Link>
              </li>
              <li>
                <Link to="/cennik">Cennik</Link>
              </li>
              <li>
                <Link to="/regulamin">Regulamin</Link>
              </li>
              <li>
                <Link to="/kontakt">Kontakt</Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </FooterWrapper>
  );
};

export default Footer;
