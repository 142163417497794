import React, { useState, useEffect } from "react";
import { ReservationCheckerWrapper } from "./styles";
// import DatePicker from "react-datepicker";
import moment from "moment";
// import "react-datepicker/dist/react-datepicker.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import { pl } from "react-date-range/dist/locale";
import Popup from "reactjs-popup";

// import Fade from "react-reveal/Fade";

import { checkTerm, reserve, sendEmails } from "./api";
import Swal from "sweetalert2";
// import Arrow from '../../assets/arrow_right.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLongArrowAltRight,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";

const DatePicker = ({ text, value, onClick }) => (
  <div className="datepicker" onClick={onClick}>
    <span className="text">{text}</span>
    <span className="day">{moment(value).format("D")}</span>
    <span className="month">{moment(value).format("MMM")}</span>
    <div className="arrow" />
  </div>
);

const STATUS_LIST = {
  CHECK: "CHECK",
  AVAILABLE: "AVAILABLE",
  UNAVAILABLE: "UNAVAILABLE",
  DONE: "DONE",
  ERROR: "ERROR",
};

const ReservationChecker = ({ variant, color }) => {
  const [guests, setGuests] = useState(1);

  const [status, setStatus] = useState(STATUS_LIST.CHECK);
  const [checking, setChecking] = useState(false);

  const [isPickerOpen, setPickerOpen] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: moment().add(1, "day").toDate(),
      endDate: moment().add(3, "day").toDate(),
      key: "selection",
    },
  ]);

  async function _checkTerm() {
    setChecking(true);
    const response = await checkTerm(
      moment(dateRange[0].startDate).startOf("day").format(),
      moment(dateRange[0].endDate).endOf("day").format()
    );

    if (response.events.length !== 0) {
      setStatus(STATUS_LIST.UNAVAILABLE);
    } else {
      setStatus(STATUS_LIST.AVAILABLE);
    }
    setChecking(false);
  }

  async function _reserve() {
    Swal.mixin({
      input: "text",
      confirmButtonText: "Dalej &rarr;",
      showCancelButton: true,
      cancelButtonText: "Anuluj",
      progressSteps: ["1", "2", "3"],
      inputAttributes: {
        required: true,
      },
      validationMessage: "Pole jest wymagane!",
    })
      .queue([
        {
          title: "Tworzenie rezerwacji...",
          text: "Podaj numer telefonu",
        },
        {
          title: "Tworzenie rezerwacji...",
          text: "Podaj adres E-Mail",
        },
        {
          title: "Tworzenie rezerwacji...",
          text: "Podaj imię i nazwisko na jakie ma zostać dokonana rezerwacja",
        },
      ])
      .then((result) => {
        if (result.value) {
          const answers = result.value;
          const phone = answers[0];
          const email = answers[1];
          const name = answers[2];

          Swal.fire({
            title: "Już prawie koniec.",
            html: `
            Czy dane się zgadzają?<br /><br />
            <b>Od:</b> ${moment(dateRange[0].startDate).format(
              "Do MMMM YYYY"
            )} 15:00<br />
            <b>Do:</b> ${moment(dateRange[0].endDate).format(
              "Do MMMM YYYY"
            )} 11:00<br />
            <b>Gości:</b> ${guests}<br />
            <b>Telefon:</b> ${phone}<br />
            <b>E-Mail:</b> ${email}<br />
            <b>Nazwisko:</b> ${name}
          `,
            showCancelButton: true,
            cancelButtonText: "Nie, popraw",
            confirmButtonText: "Tak, zarezerwuj",
            showLoaderOnConfirm: true,
            preConfirm: async () => {
              await reserve(
                moment(dateRange[0].startDate).startOf("day").format(),
                moment(dateRange[0].endDate).endOf("day").format(),
                guests,
                email,
                phone,
                name
              );
              await sendEmails({
                email,
                phone,
                name,
                startDate: moment(dateRange[0].startDate).format(),
                endDate: moment(dateRange[0].endDate).format(),
              });
            },
          }).then((result) => {
            if (result.value) {
              Swal.fire({
                icon: "success",
                title: "To już wszystko!",
                text:
                  "Rezerwacja przebiegła pomyślnie. Wkrótce się z tobą skontaktujemy.",
              });

              setStatus(STATUS_LIST.DONE);
            }
          });
        }
      });
  }

  useEffect(() => {
    const differenceInDays = moment(dateRange[0].endDate).diff(
      moment(dateRange[0].startDate),
      "days"
    );

    setStatus(differenceInDays < 2 ? STATUS_LIST.ERROR : STATUS_LIST.CHECK);
  }, [dateRange]);

  return (
    <ReservationCheckerWrapper id="reserve" variant={variant} color={color}>
      <div className="popup-wrapper">
        <Popup open={isPickerOpen} onClose={() => setPickerOpen(false)} modal>
          <DateRange
            editableDateInputs={true}
            onChange={(item) => {
              setStatus(STATUS_LIST.CHECK);
              setDateRange([item.selection]);
            }}
            moveRangeOnFirstSelection={false}
            ranges={dateRange}
            startDatePlaceholder="Od"
            endDatePlaceholder="Do"
            locale={pl}
          />
        </Popup>
      </div>
      <div className="info">
        SPRAWDŹ DOSTĘPNOŚĆ <br />
        POKOJU I <strong>REZERWUJ:</strong>
      </div>
      <div onClick={() => setPickerOpen(true)}>
        <DatePicker
          value={moment(dateRange[0].startDate)}
          text="REZERWACJA OD"
        />
      </div>
      <div onClick={() => setPickerOpen(true)}>
        <DatePicker value={dateRange[0].endDate} text="REZERWACJA DO" />
      </div>
      <div className="guestpicker">
        <span className="text">GOŚCI</span>
        <span className="number">{guests}</span>
        <div
          className="arrowUp"
          onClick={() => setGuests(guests + 1 > 6 ? 6 : guests + 1)}
        />
        <div
          className="arrowDown"
          onClick={() => setGuests(guests - 1 > 0 ? guests - 1 : 1)}
        />
      </div>
      {status === STATUS_LIST.CHECK && (
        <div className="button" onClick={_checkTerm}>
          {!checking ? (
            "SPRAWDŹ DOSTĘPNOŚĆ"
          ) : (
            <FontAwesomeIcon icon={faSpinner} size="3x" spin />
          )}
        </div>
      )}
      {status === STATUS_LIST.AVAILABLE && (
        <div className="button available" onClick={_reserve}>
          TERMIN WOLNY.
          <span>
            REZERWUJ <FontAwesomeIcon icon={faLongArrowAltRight} />
          </span>
        </div>
      )}
      {status === STATUS_LIST.UNAVAILABLE && (
        <div className="button unavailable" disabled>
          TERMIN ZAJĘTY
        </div>
      )}
      {status === STATUS_LIST.DONE && (
        <div className="button done" disabled>
          ZAREZERWOWANO POMYŚLNIE
        </div>
      )}
      {status === STATUS_LIST.ERROR && (
        <div className="button unavailable" disabled>
          TERMIN NIE MOŻE BYĆ KRÓTSZY NIŻ 2 DNI
        </div>
      )}
    </ReservationCheckerWrapper>
  );
};

export default ReservationChecker;
