import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
* {
  box-sizing: border-box;
}

  html {
    font-size: 10px;
  }

  body {
    margin: 0;
    font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  
  .button {
    color: #111111;
    letter-spacing: 0.3rem;
    font-size: 1.3rem;
    display: inline-flex;
    padding: 12px 30px;
    border: 1px solid ${props => props.theme.accentColor};
    justify-content: center;
    align-items: center;
    transform: 0.2s ease all;
    cursor: pointer;
    font-family: Lato;
    font-weight: 700;
    text-decoration: none;

    &:hover {
      opacity: 0.85;
      background: ${props => props.theme.accentColor};
      color: white;
    }
  }

  .react-datepicker__month-container {
    font-size: 1rem;
  }

  .swal2-popup {
    font-size: 150%;
  }

  @media screen and (max-width: 1280px) {
    img {
      max-width: 100%;
    }
  }
`