import React from "react";
import { NavigationWrapper } from "./styles";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight, faBars } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const Navigation = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <NavigationWrapper>
      <div className="brand">
        <Link to="/">U Pirata</Link>
      </div>
      <div className="hamburger-button" onClick={() => setOpen(!isOpen)}>
        <FontAwesomeIcon icon={faBars} size="2x" />
      </div>
      <ul className={isOpen ? "active" : ""}>
        <li>
          <Link to="/apartament">Apartament</Link>
        </li>
        <li>
          <Link to="/galeria">Galeria</Link>
        </li>
        <li>
          <Link to="/cennik">Cennik</Link>
        </li>
        <li>
          <Link to="/regulamin">Regulamin</Link>
        </li>
        <li>
          <Link to="/kontakt">Kontakt</Link>
        </li>
      </ul>
      <Link
        to="/apartament"
        className={`${isOpen && "active"} reserve-now-wrapper`}
      >
        <div className="reserve-now">
          ZAREZERWUJ <FontAwesomeIcon icon={faLongArrowAltRight} />
        </div>
      </Link>
    </NavigationWrapper>
  );
};

export default Navigation;
