import React from "react";
import Layout from "../../components/Layout";
import { RulesWrapper } from "./styles";

import HeaderImage from "../../assets/rules.jpg";
import { PrismicClient } from "../../prismic";
import { RichText } from "prismic-reactjs";

const Rules = () => {
  const [doc, setDocData] = React.useState(null);

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await PrismicClient.getSingle("rules");
      if (response) {
        setDocData(response.data);
      }
    };
    fetchData();
  }, []);

  return (
    <Layout title={doc?.title} loading={!doc} headerBackground={HeaderImage}>
      <RulesWrapper>
        <div className="content-wrapper">
          <RichText render={doc?.content} />
        </div>
      </RulesWrapper>
    </Layout>
  );
};

export default Rules;
