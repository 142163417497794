import React from "react";
import { ContactWrapper } from "./styles";
import Layout from "../../components/Layout";

import Phone from "../../assets/contact/phone.png";
import Pin from "../../assets/contact/pin.png";
import Mail from "../../assets/contact/mail.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";

import Fade from "react-reveal/Fade";
import { PrismicClient } from "../../prismic";
import { RichText } from "prismic-reactjs";

const Contact = () => {
  const [doc, setDocData] = React.useState(null);

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await PrismicClient.getSingle("contact");
      if (response) {
        setDocData(response.data);
      }
    };
    fetchData();
  }, []);

  return (
    <Layout title={doc?.title} loading={!doc} showFooterReservationChecker>
      <ContactWrapper>
        <div className="content-wrapper">
          <h5>KONTAKT Z NAMI</h5>
          <RichText render={doc?.contact_title} />
          <div className="grid">
            <Fade right cascade>
              <div>
                <img src={Phone} alt="telefon" />
                <strong>Telefon</strong>
                <RichText render={doc?.phone} />
              </div>
              <div>
                <img src={Pin} alt="adres" />
                <strong>Adres</strong>
                <RichText render={doc?.address} />
              </div>
              <div>
                <img src={Mail} alt="email" />
                <strong>Email</strong>
                <RichText render={doc?.email} />
              </div>
            </Fade>
          </div>
          <hr />
          <h5>WYŚLIJ NAM WIADOMOŚĆ</h5>
          <RichText render={doc?.form_title} />
          <p className="info">{doc?.form_content}</p>
          <form action="https://formspree.io/xvobabry" method="POST">
            <input placeholder="Imię i nazwisko" type="text" name="name" />
            <input placeholder="Email" type="email" name="email" />
            <input placeholder="Temat" type="text" name="title" />
            <textarea placeholder="Twoja wiadomość" rows="10" name="message" />
            <button type="submit" className="button">
              WYŚLIJ WIADOMOŚĆ
              <FontAwesomeIcon icon={faLongArrowAltRight} className="more" />
            </button>
          </form>
        </div>
        <iframe
          title="Mapa"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2602.047254091527!2d19.864176215645408!3d49.294447979332574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4715ecd832611c4d%3A0x798f8e17658fc06a!2sStaszel%C3%B3wka%2043%2C%2034-511%20Ko%C5%9Bcielisko!5e0!3m2!1spl!2spl!4v1586432124766!5m2!1spl!2spl"
          frameborder="0"
          aria-hidden="false"
          tabindex="0"
          className="map"
        />
      </ContactWrapper>
    </Layout>
  );
};

export default Contact;
