import styled from "styled-components";

export const GalleryWrapper = styled.main`
  .gallery {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
  }

  iframe {
    margin-top: 25px;
    width: 100%;
  }

  p {
    font-size: 1.5rem;
    color: #8a8a8a;
    margin: 50px auto;
    width: 50%;
    text-align: center;
  }

  .image-wrapper {
    width: 400px;
    height: 400px;
    overflow: hidden;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    transition: transform 0.2s ease;

    &:hover {
      transform: scale(1.1);
    }
  }

  @media screen and (max-width: 1280px) {
    .gallery {
      grid-template-columns: 1fr;

      img {
        margin: 0 auto;
      }
    }

    p {
      width: auto;
      padding: 10px;
    }

    .image-wrapper {
      width: 100%;
      height: auto;
    }
  }
`;
