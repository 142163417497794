import React from "react";
import Layout from "../../components/Layout";
import { PricingWrapper } from "./styles";
import Slider from "../../components/Slider";

import HeaderImage from "../../assets/pricing.jpg";
import { PrismicClient } from "../../prismic";

import { RichText } from "prismic-reactjs";

const Pricing = () => {
  const [doc, setDocData] = React.useState(null);

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await PrismicClient.getSingle("pricing");
      if (response) {
        setDocData(response.data);
      }
    };
    fetchData();
  }, []);

  return (
    <Layout
      title={doc?.title}
      showFooterReservationChecker
      headerBackground={HeaderImage}
      loading={!doc}
    >
      <PricingWrapper>
        <div className="content-wrapper">
          <div className="main-grid">
            <div>
              <RichText render={doc?.content} />
              <hr />
              <RichText render={doc?.content2} />
            </div>
            <div className="price">
              <div className="text">
                CENA POKOJU:
                <br />
                <strong>{doc?.frame_text}</strong>
              </div>
              <a className="button" href="#reserve">
                REZERWUJ POKÓJ
              </a>
            </div>
          </div>
        </div>
      </PricingWrapper>
      <Slider />
    </Layout>
  );
};

export default Pricing;
