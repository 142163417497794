import styled from "styled-components";

export const HomeWrapper = styled.main`
  font-size: 1.5rem;

  .more {
    margin-left: 20px !important;
  }

  .title {
    position: relative;
    padding-top: 60px;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;

    h3 {
      font-size: 1.6rem;
      line-height: 1;
      font-weight: 700;
      font-family: Lato;
      letter-spacing: 0.3rem;
      margin-bottom: 0;
    }

    .shadow {
      position: absolute;
      font-size: 16rem;
      color: #f2f2f2;
      bottom: 0;
      line-height: 1;
      font-family: "Playfair Display";
      z-index: -1;
    }
  }

  .grid-1,
  .grid-2 {
    display: grid;
    grid-gap: 40px;

    .text {
      display: grid;
      grid-gap: 16px;
      align-content: center;
      justify-content: center;
      margin-right: 60px;
      max-width: 330px;

      h4 {
        text-align: center;
        font-family: "Playfair Display";
        font-size: 4.2rem;
        margin: 0;
      }

      hr {
        width: 75px;
        margin: 10px auto;
      }

      p {
        color: #8a8a8a;
        text-align: center;
        margin: 0;
      }

      .button {
        justify-self: center;
      }
    }
  }

  .grid-1 {
    grid-template-columns: auto 1fr 1fr;
  }

  .grid-2 {
    grid-template-columns: auto 1fr;
  }

  .hr {
    border-color: #dbdbdb;
    margin: 65px 0;
    border-width: 1px;
  }

  .grid-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    margin: 40px 0;

    img {
      width: 100%;
    }
  }

  .offer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);

    .info {
      grid-column-end: span 3;
      display: grid;
      grid-gap: 15px;
      justify-content: center;
      text-align: center;
      padding: 50px;

      h5 {
        color: ${(props) => props.theme.accentColor};
        font-size: 1.4rem;
        margin: 0;
        font-family: Lato;
        letter-spacing: 0.3rem;
        font-weight: 400;
      }

      h4 {
        font-family: "Playfair Display";
        font-size: 4.8rem;
        margin: 0;
      }

      p {
        font-size: 1.5rem;
        color: #8a8a8a;
        margin: 0;
      }
    }

    .item {
      border: 1px solid #d8d8d8;
      padding: 50px;
      display: grid;
      grid-gap: 15px;
      align-content: center;
      justify-content: center;

      &:not(:last-child) {
        border-right: none;
      }

      img {
        margin: 0 auto;
      }

      h4 {
        text-align: center;
        font-family: "Playfair Display";
        font-size: 4.2rem;
        margin: 0;
      }

      hr {
        width: 50px;
      }

      p {
        color: #8a8a8a;
        text-align: center;
      }

      .button {
        justify-self: center;
      }
    }
  }

  @media screen and (max-width: 1280px) {
    .shadow {
      display: none;
    }

    .grid-1,
    .grid-2,
    .offer {
      grid-template-columns: 1fr;
      grid-auto-flow: row;
      text-align: center;

      .text {
        margin-right: 0 !important;
        justify-self: center;
      }

      .info {
        grid-column-end: span 1;
      }
    }

    h3 {
      text-align: center;
      max-width: 85%;
    }
  }
`;
