import styled from "styled-components";

export const LayoutWrapper = styled.div`
  .content-wrapper {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
  }

  main {
    padding-top: 150px;
  }

  @media screen and (max-width: 1280px) {
    main {
      padding-top: 20px;
    }
  }
`;
